// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "s_rK d_bB";
export var storyRowWrapper = "s_ht d_ht d_bH";
export var storyLeftWrapper = "s_rL d_bx d_bM";
export var storyWrapperFull = "s_rM d_cB";
export var storyWrapperFullLeft = "s_nL d_cB d_bx d_bM d_bB";
export var contentWrapper = "s_mw d_hv";
export var storyLeftWrapperCenter = "s_rN d_hw d_bx d_bB d_bM d_bG";
export var storyRightWrapperCenter = "s_rP d_hB";
export var storyHeader = "s_rQ d_hz d_t d_cq";
export var storyHeaderCenter = "s_hy d_hy d_t d_cq d_dt d_bw";
export var storyParagraphCenter = "s_hx d_hx d_bw d_dt";
export var storyBtnWrapper = "s_rR d_d0 d_dZ d_t d_bx d_bD";
export var storyBtnWrapperCenter = "s_hF d_hF d_dZ d_t d_bx d_bB";
export var imageWrapper = "s_rd d_fd d_X";
export var imageWrapperFull = "s_rf d_t d_F d_bc d_X";