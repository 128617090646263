// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_rq d_gr d_cq";
export var heroHeaderCenter = "r_gs d_gs d_cq d_dt";
export var heroHeaderRight = "r_gt d_gt d_cq d_dv";
export var heroParagraphLeft = "r_rr d_gn d_ct";
export var heroParagraphCenter = "r_gp d_gp d_ct d_dt";
export var heroParagraphRight = "r_gq d_gq d_ct d_dv";
export var heroBtnWrapperLeft = "r_rs d_d0 d_dZ d_t d_bx d_bD";
export var heroBtnWrapperCenter = "r_rt d_d1 d_dZ d_t d_bx d_bB";
export var heroBtnWrapperRight = "r_rv d_d2 d_dZ d_t d_bx d_bF";
export var overlayBtnWrapper = "r_rw d_gm d_Y d_5 d_6 d_7 d_bl d_b9";
export var design4 = "r_rx d_gl d_Y d_5 d_6 d_bl";
export var heroExceptionSmall = "r_ry x_ry";
export var heroExceptionNormal = "r_rz x_rz";
export var heroExceptionLarge = "r_rB x_rB";
export var Title1Small = "r_rC x_rC x_sh x_sj";
export var Title1Normal = "r_rD x_rD x_sh x_sk";
export var Title1Large = "r_rF x_rF x_sh x_sl";
export var BodySmall = "r_rG x_rG x_sh x_sB";
export var BodyNormal = "r_rH x_rH x_sh x_sC";
export var BodyLarge = "r_rJ x_rJ x_sh x_sD";