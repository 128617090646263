// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "q_qS d_bB";
export var datasheetRowWrapper = "q_lr d_lr d_bH";
export var datasheetLeftWrapper = "q_qT d_bx d_bM";
export var datasheetWrapperFull = "q_qV d_cB";
export var datasheetWrapperFullLeft = "q_nF d_cB d_bx d_bM d_bB d_bR";
export var contentWrapper = "q_mw d_ls";
export var contentWrapperCenter = "q_qW d_lx";
export var datasheetLeftWrapperCenter = "q_qX d_lv d_bx d_bB d_bM d_bG";
export var datasheetImageCenterWrapper = "q_lp d_lp d_t d_cs";
export var datasheetRightWrapperCenter = "q_qY d_lt";
export var datasheetFooter = "q_lw d_lw";
export var alignLeft = "q_p3 d_ds";
export var alignCenter = "q_bM d_dt";
export var alignRight = "q_p4 d_dv";
export var datasheetInnerWrapperNewLeft = "q_qZ d_fc d_M d_X d_bx d_bM d_dg";
export var datasheetInnerWrapperLeft = "q_q0 d_fb d_M d_X d_bx d_bM d_b3 d_dg d_c7";
export var datasheetInnerWrapperRight = "q_q1 d_fc d_M d_X d_bx d_bM d_dg";
export var datasheetRowWrapperReverse = "q_q2 d_bH";
export var datasheetSubtitle = "q_q3 d_cv";
export var tableRow = "q_q4";
export var cell = "q_q5";
export var tableRowWrapper = "q_q6 d_t d_ds";
export var tableHeadingCell = "q_q7";
export var tableHeading = "q_q8";
export var tableRowStriped = "q_q9";
export var tableRowHeading = "q_rb";
export var dataTable = "q_rc d_t";
export var imageWrapper = "q_rd d_fd d_X";
export var imageWrapperFull = "q_rf d_F d_t d_bc d_X";
export var imageWrapperIcon = "q_rg";
export var titleMargin = "q_rh d_cq";
export var datasheetInnerInnerWrapper = "q_rj d_t";
export var hasLabels = "q_rk";
export var label = "q_rl";
export var SmallSmall = "q_rm x_rm x_sh x_sF";
export var SmallNormal = "q_rn x_rn x_sh x_sG";
export var SmallLarge = "q_rp x_rp x_sh x_sC";