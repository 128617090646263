// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_tm";
export var lbContainerInner = "y_tn";
export var movingForwards = "y_tp";
export var movingForwardsOther = "y_tq";
export var movingBackwards = "y_tr";
export var movingBackwardsOther = "y_ts";
export var lbImage = "y_tt";
export var lbOtherImage = "y_tv";
export var prevButton = "y_tw";
export var nextButton = "y_tx";
export var closing = "y_ty";
export var appear = "y_tz";